import { useMemo } from "react";

import { StripeCreateOrLinkAccount } from "./stripe-create-or-link-account";
import { StripeDashboard } from "./stripe-dashboard";
import { toTenantIdHeader, useApiQuery } from "../../api/use-api";
import { DefaultContentContainer } from "../../layouts/default-content-container";
import { RETRIEVE_ACCOUNT_KEY } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { LoadingSpinner } from "../loading-spinner";

export const StripeAdminManager: React.FC = () => {
  const tenantId = useTenantId();
  const { data: stripeAccount, isFetched: isAccountFetched } = useApiQuery(
    "backend",
    (api) => api.getStripeAccount(toTenantIdHeader(tenantId)),
    RETRIEVE_ACCOUNT_KEY(tenantId),
    { trackErrors: false },
    { retry: false },
  );

  const hasLinkedAccount = useMemo(
    () => stripeAccount?.chargesEnabled,
    [stripeAccount?.chargesEnabled],
  );

  return (
    <DefaultContentContainer>
      <div className="flex items-center justify-center">
        {!isAccountFetched ? (
          <LoadingSpinner
            className="scale-125 flex-col gap-4"
            message={{ tx: "stripe.loadingStripeSettings" }}
          />
        ) : hasLinkedAccount ? (
          <StripeDashboard />
        ) : (
          <StripeCreateOrLinkAccount />
        )}
      </div>
    </DefaultContentContainer>
  );
};
