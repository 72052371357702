import { useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";

import { ManageAccountModal } from "./manage-account-modal";
import { useProfileCompleteness } from "./use-profile-completeness";
import {
  GroupInfoDtoGroupEnum,
  MultipleMembershipsDto,
  UpdateSettingsDto,
  UpdateUserAttributesDto,
  UpdateUserDetailsDto,
} from "../../api/generated/backend";
import { toTenantIdHeader, useApiMutation } from "../../api/use-api";
import { Form } from "../../components/form/form";
import { LoadingButton } from "../../components/form/loading-button";
import { LoadingSpinner } from "../../components/loading-spinner";
import { useModal } from "../../models/modal-provider";
import { useTenantConfig } from "../../tenant-settings";
import { FOLLOWED_PAGS_KEY, ME_KEY } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { useUser } from "../../util/use-user";
import { formDataCollectionInputLookup } from "../guard/implementations/form/form-data-guards";
import {
  FormDataCollectionContext,
  UpdateUserProfile,
} from "../guard/implementations/form/types";

export interface Profile {
  hideProfile: () => void;
  showProfile: () => void;
}

export const useProfile: () => Profile = () => {
  const { showModal: showProfileModal, hideModal: hideProfileModal } =
    useModal();
  const { showModal: showWarningModal, hideModal: hideWarningModal } =
    useModal();
  const [hasChanges, setHasChanges] = useState(false);
  const hasChangesRef = useRef(hasChanges);

  const [ignoreChanges, setIgnoreChanges] = useState(false);
  const ignoreChangesRef = useRef(ignoreChanges);

  useEffect(() => {
    hasChangesRef.current = hasChanges;
  }, [hasChanges]);

  useEffect(() => {
    ignoreChangesRef.current = ignoreChanges;
    if (ignoreChanges) {
      hideProfileModal();
      hideWarningModal();
      setIgnoreChanges(false);
    }
  }, [ignoreChanges, hideProfileModal, hideWarningModal]);

  const showWarning = useCallback(() => {
    showWarningModal({
      title: { tx: "profile.unsavedChangesTitle" },
      description: { tx: "profile.unsavedChanges" },
      onConfirm: () => {
        setIgnoreChanges(true);
      },
      confirmButtonTx: "general.yes",
      closeButtonTx: "general.no",
    });
  }, [showWarningModal, setIgnoreChanges]);

  const ensureCanClose = useCallback(() => {
    if (hasChangesRef.current) {
      showWarning();
    }
    return !hasChangesRef.current || ignoreChangesRef.current;
  }, [showWarning]);

  const showProfile = useCallback(() => {
    showProfileModal({
      showsWindowCloseButton: true,
      closeOnClickOutside: true,
      showCloseButton: false,
      title: { tx: "profile.headerTitle" },
      ensureCanClose,
      children: (
        <div>
          <Profile
            onSavedProfile={hideProfileModal}
            setHasChanges={setHasChanges}
          />
        </div>
      ),
    });
  }, [hideProfileModal, showProfileModal, ensureCanClose]);

  return { showProfile, hideProfile: hideProfileModal };
};

export const Profile: React.FC<{
  onSavedProfile: () => void;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
}> = ({ onSavedProfile, setHasChanges }) => {
  const queryClient = useQueryClient();
  const tenantId = useTenantId();
  const tenantConfig = useTenantConfig();
  const user = useUser();

  const {
    control,
    register,
    formState: { errors, isSubmitting, isDirty: hasChanges },
    handleSubmit,
  } = useForm<UpdateUserProfile>({
    defaultValues: {
      ...user?.userAttributes,
      ...user?.userDetails,
      ...user?.settings,
      pags:
        user?.organisationMemberGroups.map((group) => group.organisation) ?? [],
    },
  });

  useEffect(() => {
    setHasChanges(hasChanges);
  }, [setHasChanges, hasChanges]);

  const inputs = useMemo(
    () =>
      tenantConfig.profileInputs.map((inputType) => {
        return formDataCollectionInputLookup[inputType];
      }),
    [tenantConfig.profileInputs],
  );

  const { mutate: updateUserAttributes } = useApiMutation(
    "backend",
    (api) => (updateUserAttributesDto: UpdateUserAttributesDto) =>
      api.updateUserAttributes({ updateUserAttributesDto }),
    undefined,
    { successMessage: { tx: "profile.updateSuccessful" }, trackErrors: true },
    {
      onSuccess() {
        queryClient.invalidateQueries(ME_KEY(tenantId));
        onSavedProfile();
      },
    },
  );

  const { mutate: updateUserDetails } = useApiMutation(
    "backend",
    (api) => (updateUserDetailsDto: UpdateUserDetailsDto) =>
      api.updateUserDetails({
        updateUserDetailsDto,
        ...toTenantIdHeader(tenantId),
      }),
    undefined,
    { trackErrors: true },
    {
      onSuccess() {
        queryClient.invalidateQueries(ME_KEY(tenantId));
        onSavedProfile();
      },
    },
  );

  const { mutate: updateSettings } = useApiMutation(
    "backend",
    (api) => (updateSettingsDto: UpdateSettingsDto) =>
      api.updateSettings({
        updateSettingsDto,
        ...toTenantIdHeader(tenantId),
      }),
    undefined,
    { trackErrors: true },
    {
      onSuccess() {
        queryClient.invalidateQueries(ME_KEY(tenantId));
        onSavedProfile();
      },
    },
  );

  const { mutate: updateOrganisationMemeberships } = useApiMutation(
    "backend",
    (api) => (multipleMembershipsDto: MultipleMembershipsDto) =>
      api.updateOrganisationMemeberships({
        multipleMembershipsDto,
        ...toTenantIdHeader(tenantId),
      }),
    undefined,
    undefined,
    {
      onSuccess() {
        queryClient.invalidateQueries(ME_KEY(tenantId));
        queryClient.invalidateQueries(FOLLOWED_PAGS_KEY(tenantId.disease));
      },
    },
  );

  const updateProfileHandler = handleSubmit(async (data: UpdateUserProfile) => {
    setHasChanges(false);
    i18next.changeLanguage(data.language ?? "en");
    updateUserAttributes(data);
    updateUserDetails(data);
    updateSettings(data);
    updateOrganisationMemeberships({
      groupInfos: data.pags.map((pag) => ({
        organisation: pag,
        group: GroupInfoDtoGroupEnum.Member,
      })),
    });
  });

  const { isLoading } = useProfileCompleteness();

  return user && !isLoading ? (
    <div className="flex flex-col gap-4">
      <Form onSubmit={updateProfileHandler}>
        <div className="flex w-full shrink-0 flex-col gap-2 xl:max-w-[600px] 2xl:max-w-[700px]">
          {inputs.map((input, index) => (
            <input.Component
              key={index}
              user={user}
              register={register}
              control={control}
              errors={errors}
              context={FormDataCollectionContext.PROFILE}
            />
          ))}
          <LoadingButton
            isDisabled={!hasChanges}
            loading={isSubmitting}
            tx="general.confirm"
            className="mt-4"
          />
        </div>
      </Form>
      <ManageAccountModal />
    </div>
  ) : (
    <LoadingSpinner className="justify-center" />
  );
};
