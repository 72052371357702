import { useEffect, useRef } from "react";
import { TranslationFeKey } from "shared/types/translation-key";
import { twMerge } from "tailwind-merge";

import { LoadingSpinner } from "../../../loading-spinner";
import { ChatMessageComponent } from "../../generic/chat-message";
import { ChatMessage } from "../../types";

export type MessageListProps = {
  isChatBusy: boolean;
  chatMessages: ChatMessage[];
  chatLoadingMessage?: TranslationFeKey;
  userChatColors?: { bubbleColor?: string; textColor?: string };
};

export const MessageList: React.FC<MessageListProps> = ({
  isChatBusy,
  chatMessages,
  chatLoadingMessage,
  userChatColors,
}) => {
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  return (
    <div className="grow overflow-y-auto">
      {chatMessages.map((chatMessage, index) => (
        <ChatMessageComponent
          key={index}
          message={chatMessage}
          className={index === 0 ? "mt-4" : ""}
          userChatColors={userChatColors}
        />
      ))}

      {isChatBusy && (
        <div className="py-2">
          <LoadingSpinner
            message={{ tx: chatLoadingMessage ?? "chat.loading" }}
            className={twMerge(isChatBusy ? "justify-start" : "justify-end")}
          />
        </div>
      )}
      <div ref={bottomRef} />
    </div>
  );
};
