import { useEffect, useState } from "react";
import { IFramePage } from "shared/model/pages";

import { useObservedJwtToken } from "../auth/auth-store-context";
import { LoadingSpinner } from "../components/loading-spinner";
import { useGetPageInfo } from "../router/use-get-page-info";
import { TenantPage } from "../router/use-tenant-pages";
import { setCookie } from "../util/cookie";
import { useUser } from "../util/use-user";

export const IFrameRoute: React.FC<{
  page: TenantPage & { page: IFramePage };
}> = ({ page }) => {
  const user = useUser();
  const getPageInfo = useGetPageInfo();
  const [shouldIFrameBeDisplayed, setShouldIFrameBeDisplayed] = useState(false);

  const jwt = useObservedJwtToken();

  useEffect(() => {
    if (page.page.passJwt) {
      setCookie({
        name: "auth_jwt_token",
        value: jwt,
        domain: `.${location.hostname}`,
      });
    }
    setShouldIFrameBeDisplayed(true);
  }, [jwt, page.page.passJwt]);

  return shouldIFrameBeDisplayed ? (
    <iframe
      className="h-full w-full"
      src={`${getPageInfo(page).iFrameUrl}?nickname=${
        user?.userAttributes?.name
      }&email=${user?.userAttributes?.email}&postalCode=${
        user?.userAttributes?.postalCode
      }&userId=${user?.id}`}
      allowFullScreen={page.page.allowFullScreen}
    />
  ) : (
    <LoadingSpinner />
  );
};
