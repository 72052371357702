import React, {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { basePathOfTenant } from "shared/config/base-urls";
import { EnabledPage } from "shared/model/pages";
import { twMerge } from "tailwind-merge";

import { ChatTutorial } from "./chat/chat-tutorial";
import { GhostButton, OutlineButton } from "./form/button";
import { MenuDropdown } from "./form/menu-dropdown";
import { SvgIcon } from "./icons/svg-icon";
import { Iframe } from "./iframe";
import { useIsPageLocked } from "./lock";
import { SettingsDropdown } from "./settings-dropdown";
import { Text } from "./text";
import { UserTenantGroupsEnum } from "../api/generated/backend";
import { toTenantIdHeader, useApiQuery } from "../api/use-api";
import { useAuthStore } from "../auth/auth-store-context";
import { useProfile } from "../data-collection/profile/profile";
import { useT } from "../i18n/use-t";
import { useModal } from "../models/modal-provider";
import { useTenantConfig } from "../tenant-settings";
import { ME_KEY } from "../types/query-keys";
import { useDimensions } from "../util/hooks";
import { useMetadataForActiveRoute } from "../util/metadata-for-active-route";
import { useTenantId } from "../util/use-active-tenant-id";

type MenuBarPosition = "start" | "end";

export interface ChildNavbarRoute {
  page?: EnabledPage;
  path: string;
  title: string;
  isActive: boolean;
  position: MenuBarPosition;
  isLocked?: boolean;
}

export interface ParentNavbarRoute {
  childRoutes: ChildNavbarRoute[];
  title: string;
  uniqueKey: string;
  position: MenuBarPosition;
}

export type NavbarRoute = ChildNavbarRoute | ParentNavbarRoute;

export interface HeaderProps {
  navbarRoutes: NavbarRoute[];
  currentPage?: EnabledPage;
}

export const Header: React.FC<HeaderProps> = ({ navbarRoutes }) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const wideHomeIconRef = useRef<HTMLImageElement>(null);
  const desktopNavbarRef = useRef<HTMLDivElement>(null);
  const desktopButtonsRef = useRef<HTMLDivElement>(null);
  const mobileButtonsRef = useRef<HTMLDivElement>(null);

  const headerDimensions = useDimensions(headerRef);
  const wideHomeIconDimensions = useDimensions(wideHomeIconRef);
  const desktopNavbarDimensions = useDimensions(desktopNavbarRef);
  const desktopButtonsDimensions = useDimensions(desktopButtonsRef);
  const mobileButtonsDimensions = useDimensions(mobileButtonsRef);

  const desktopRequiredWidth = Math.max(
    2 * (wideHomeIconDimensions.width + desktopNavbarDimensions.width / 2),
    2 * (desktopButtonsDimensions.width + desktopNavbarDimensions.width / 2),
  );

  const wideHomeIconInMobileRequiredWidth =
    wideHomeIconDimensions.width + mobileButtonsDimensions.width;

  const showDesktopMenu = desktopRequiredWidth < headerDimensions.width;

  const showWideHomeIcon =
    wideHomeIconInMobileRequiredWidth < headerDimensions.width;

  const ready = !!(
    wideHomeIconDimensions.width &&
    desktopNavbarDimensions.width &&
    desktopButtonsDimensions.width &&
    mobileButtonsDimensions.width &&
    headerDimensions.width
  );

  useMetadataForActiveRoute(navbarRoutes);

  return (
    <div className="z-[1001] bg-white p-3 drop-shadow-xl  sm:p-6">
      <div
        className="grid grid-cols-[1fr_7fr_1fr] items-center"
        ref={headerRef}
      >
        <WideHomeIcon
          ref={wideHomeIconRef}
          hidden={!ready || !showWideHomeIcon}
        />
        <NarrowHomeIcon hidden={ready && showWideHomeIcon} />
        <DesktopMenu
          hidden={!ready || !showDesktopMenu}
          navbarRef={desktopNavbarRef}
          buttonsRef={desktopButtonsRef}
          navbarRoutes={navbarRoutes}
        />
        <MobileMenu
          hidden={ready && showDesktopMenu}
          burgerAndLanguageSelectorRef={mobileButtonsRef}
          navbarRoutes={navbarRoutes}
        />
      </div>
    </div>
  );
};

const WideHomeIcon = forwardRef<HTMLImageElement, { hidden: boolean }>(
  ({ hidden }, ref) => {
    const config = useTenantConfig();
    return (
      <div
        className={twMerge(
          "absolute opacity-0",
          hidden ? "pointer-events-none" : "relative opacity-100",
        )}
      >
        <Link
          className="relative block w-fit min-w-fit"
          to={basePathOfTenant({ disease: config.disease })}
        >
          <img
            ref={ref}
            src={config?.headerLogo}
            className="h-12 min-w-fit object-contain object-left"
          />
        </Link>
      </div>
    );
  },
);
WideHomeIcon.displayName = "WideHomeIcon";

export const NarrowHomeIcon: React.FC<{ hidden: boolean }> = ({ hidden }) => {
  const config = useTenantConfig();
  return hidden ? null : (
    <div>
      <Link
        className="relative block w-fit min-w-fit"
        to={basePathOfTenant({ disease: config.disease })}
      >
        <img
          src={config?.narrowHeaderLogo}
          className="h-12 min-w-fit object-contain object-left"
        />
      </Link>
    </div>
  );
};

const DesktopMenu: React.FC<{
  hidden: boolean;
  navbarRef: RefObject<HTMLDivElement>;
  buttonsRef: RefObject<HTMLDivElement>;
  navbarRoutes: NavbarRoute[];
  currentPage?: EnabledPage;
}> = ({ hidden, navbarRef, buttonsRef, navbarRoutes }) => {
  const { start, end } = useSplitNavbarRoutes(navbarRoutes);
  return (
    <>
      <div
        className={twMerge(
          "fixed flex grow flex-row items-center justify-center opacity-0",
          hidden ? "pointer-events-none" : "relative opacity-100",
        )}
      >
        <div ref={navbarRef} className="flex w-fit flex-row flex-nowrap gap-4">
          <ul
            className={twMerge(
              "menu menu-horizontal bg-base-200",
              "flex-nowrap rounded-lg p-0",
            )}
          >
            <NavLinks routes={start} isDesktopMenu={true} />
          </ul>

          {end.length >= 1 ? (
            <ul
              className={twMerge(
                "menu menu-horizontal bg-base-200",
                "flex-nowrap rounded-lg p-0",
              )}
            >
              <NavLinks routes={end} isDesktopMenu={true} />
            </ul>
          ) : null}
        </div>
      </div>
      <div
        className={twMerge(
          "absolute flex justify-end opacity-0",
          hidden ? "pointer-events-none" : "relative opacity-100",
        )}
      >
        <div
          className="flex shrink-0 basis-auto flex-row items-center justify-end gap-3"
          ref={buttonsRef}
        >
          <ActionButtons />
        </div>
      </div>
    </>
  );
};

const MobileMenu: React.FC<{
  hidden: boolean;
  navbarRoutes: NavbarRoute[];
  burgerAndLanguageSelectorRef: RefObject<HTMLDivElement>;
}> = ({ hidden, navbarRoutes, burgerAndLanguageSelectorRef }) => {
  const location = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);

  const { start, end } = useSplitNavbarRoutes(navbarRoutes);

  useEffect(() => {
    setIsNavOpen(!!location.state?.isNavOpen);
  }, [location.state?.isNavOpen]);

  useEffect(() => {
    if (!location.state?.isNavOpen) {
      setIsNavOpen(false);
    }
  }, [location.pathname, location.state?.isNavOpen]);

  return (
    <>
      {hidden ? null : <div />}
      <div
        ref={burgerAndLanguageSelectorRef}
        className={twMerge(
          "absolute flex justify-end gap-2 opacity-0",
          hidden ? "pointer-events-none" : "static opacity-100",
          isNavOpen ? "items-start" : "items-center",
        )}
      >
        <div
          className="z-[51] cursor-pointer space-y-2"
          onClick={() => {
            location.state = {};
            setIsNavOpen((prev) => !prev);
          }}
        >
          <SvgIcon
            height={60}
            width={60}
            className="fill-mama-gray-200"
            icon={isNavOpen ? "cross" : "apps"}
          />
        </div>

        <div className={isNavOpen ? "h-screen items-start" : ""}>
          <div
            className={
              isNavOpen
                ? "absolute left-0 top-0 z-50 flex h-full w-screen flex-col items-center justify-center bg-base-100"
                : "hidden"
            }
          >
            <div className="flex h-full w-full justify-center overflow-scroll py-4">
              <div className="flex h-full w-fit flex-col items-stretch gap-10">
                <ul className="menu rounded-lg bg-base-200 p-6">
                  <NavLinks routes={start} isDesktopMenu={false} />
                </ul>
                {end.length >= 1 ? (
                  <ul className="menu rounded-lg bg-base-200 p-6">
                    <NavLinks routes={end} isDesktopMenu={false} />
                  </ul>
                ) : null}
                <div className="flex flex-col items-stretch gap-2">
                  <ActionButtons isMobileView />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ActionButtons: React.FC<{
  isMobileView?: boolean;
}> = ({ isMobileView }) => {
  const { showProfile } = useProfile();

  const { showModal } = useModal();

  const toggleModal = useCallback(() => {
    showModal({
      showCloseButton: false,
      showsWindowCloseButton: true,
      windowCloseButtonClassName: "text-bg-blue-900 border-0",
      className: "max-w-xs",
      children: <ChatTutorial />,
    });
  }, [showModal]);

  const { signOut } = useAuthStore();
  const tenantId = useTenantId();
  const { data: user } = useApiQuery(
    "backend",
    (api) => api.getMe(toTenantIdHeader(tenantId)),
    ME_KEY(tenantId),
  );
  const isUserOrAdminUserGroup = useMemo(
    () =>
      user?.tenantGroups.includes(UserTenantGroupsEnum.User) ||
      user?.tenantGroups.includes(UserTenantGroupsEnum.Admin),
    [user],
  );

  return (
    <>
      {isUserOrAdminUserGroup && <HelpPageButton />}
      {!isMobileView && <SettingsDropdown />}
      {isMobileView && (
        <>
          <OutlineButton
            tx="profile.headerTitle"
            onClick={() => {
              showProfile();
            }}
          />
          <OutlineButton tx="profile.tutorial" onClick={toggleModal} />
          <OutlineButton
            tx="auth.signOut.signOutButton"
            isDisabled={!signOut}
            onClick={() => {
              signOut?.();
            }}
          />
        </>
      )}
    </>
  );
};

const NavLinks: React.FC<{
  routes: NavbarRoute[];
  isDesktopMenu: boolean;
}> = ({ routes, isDesktopMenu }) => {
  return (
    <>
      {routes.map((route) => (
        <li key={`navbar-routes-${route.title}`} className="my-1">
          {isParentRoute(route) ? (
            <ParentNavLink isDesktopMenu={isDesktopMenu} {...route} />
          ) : (
            <ChildNavLink {...route} />
          )}
        </li>
      ))}
    </>
  );
};

const ChildNavLink: React.FC<ChildNavbarRoute> = ({
  page,
  path,
  title,
  isActive,
}) => {
  const { isLocked } = useIsPageLocked(page);

  return (
    <NavLink
      to={path}
      style={{ textDecoration: "inherit" }}
      className={twMerge(
        "flex h-full w-full flex-row items-center bg-mama-gray-100 hover:bg-mama-green-100",
        isActive ? "!bg-mama-gray-200 !text-bg-blue-900" : "",
      )}
    >
      <Text text={title} />
      {isLocked ? (
        <SvgIcon
          icon="secure"
          className="h-7 w-auto min-w-0"
          color={isActive ? "fill-primary-content" : "fill-base-content"}
        />
      ) : null}
    </NavLink>
  );
};

const ParentNavLink: React.FC<
  ParentNavbarRoute & { isDesktopMenu: boolean }
> = ({ title, childRoutes, isDesktopMenu }) => {
  const [closeDropdown, setCloseDropdown] = useState(() => () => {});

  return childRoutes.length > 1 ? (
    <MenuDropdown
      isOpenInitially={
        !isDesktopMenu && !!childRoutes.find((route) => route.isActive)
      }
      summaryElement={
        <summary
          className={twMerge(
            "py-0 hover:bg-mama-green-100",
            isDesktopMenu && childRoutes.some(({ isActive }) => isActive)
              ? "!bg-primary !text-bg-blue-900"
              : "",
          )}
        >
          <div className="flex h-12 w-full flex-row items-center justify-center whitespace-nowrap ">
            <Text as="div" text={title} />
          </div>
        </summary>
      }
      setCloseDropdown={setCloseDropdown}
      closeOnClickOutside={isDesktopMenu}
    >
      <ul
        onClickCapture={isDesktopMenu ? closeDropdown : undefined}
        className="!mt-0 bg-base-200"
      >
        {childRoutes.map((route) => (
          <li
            key={route.path}
            className={`py-1 ${route.isActive ? "active" : ""}`}
          >
            <ChildNavLink key={route.path} {...route} />
          </li>
        ))}
      </ul>
    </MenuDropdown>
  ) : childRoutes.length === 1 ? (
    <ChildNavLink {...childRoutes[0]} title={title} />
  ) : null;
};

const HelpPageButton: React.FC = () => {
  const config = useTenantConfig();
  const { showModal } = useModal();
  const t = useT();
  const helpPageUrl =
    config?.showCallSupportButton &&
    t({ tx: config?.showCallSupportButton.helpPageUrl });

  const supportPhoneNumber =
    config?.showCallSupportButton &&
    t({ tx: config?.showCallSupportButton.telephoneNumberTx });

  return config?.showCallSupportButton ? (
    <GhostButton
      tx="general.callSupportButton"
      txData={{ supportPhoneNumber }}
      onClick={() => {
        showModal(
          helpPageUrl
            ? {
                children: <Iframe className="h-[90%]" url={helpPageUrl} />,
                className: "w-full h-full max-w-6xl",
              }
            : {
                title: { tx: "general.callUs" },
                description: {
                  tx: "general.callSupportModalDescription",
                  txData: { supportPhoneNumber },
                },
              },
        );
      }}
    />
  ) : null;
};

const isParentRoute = (route: NavbarRoute): route is ParentNavbarRoute =>
  !("path" in route);

const useSplitNavbarRoutes = (
  routes: NavbarRoute[],
): Record<MenuBarPosition, NavbarRoute[]> => {
  return {
    start: routes.filter((route) => route.position === "start"),
    end: routes.filter((route) => route.position === "end"),
  };
};
