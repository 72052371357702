import { ButtonProps, Buttons, PrimaryButton } from "./button";
import { LoadingSpinner } from "../loading-spinner";

export const LoadingButton: React.FC<
  ButtonProps & {
    loading: boolean;
    Button?: (typeof Buttons)[keyof typeof Buttons];
  }
> = ({ children, loading, isDisabled, Button = PrimaryButton, ...props }) => {
  return (
    <Button {...props} isDisabled={loading || isDisabled}>
      {loading ? (
        <>
          <LoadingSpinner />
          {children}
        </>
      ) : (
        children
      )}
    </Button>
  );
};
