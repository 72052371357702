import { signIn, signOut } from "aws-amplify/auth";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { CognitoClientMetadata } from "shared/types/cognito-client-metadata";

import { CognitoMigrateAccountWithConfirmedUser } from "./cognito-migrate-account-with-confirmed-user";
import { LoadingSpinner } from "../../../../../components/loading-spinner";
import { useTenantId } from "../../../../../util/use-active-tenant-id";
import { AuthMessageLevel } from "../../../generic/types";
import { useAuthNavigate } from "../../../generic/use-auth-navigate";
import { useCognitoConfiguration } from "../../hooks/use-cognito-configuration";
import { cognitoPaths } from "../paths";

export const CognitoMigrateAccount: React.FC = () => {
  const [params] = useSearchParams();
  const navigate = useAuthNavigate();
  const cognitoState = useCognitoConfiguration();
  const [signedIn, setSignedIn] = useState(false);
  const sentSignInRequest = useRef(false);
  const email = params.get("email");
  const { disease, organisation } = useTenantId();
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    if (!sentSignInRequest.current && cognitoState === "ready") {
      sentSignInRequest.current = true;
      const tmpPassword = params.get("accountMigrationCode");
      if (!email || !tmpPassword) {
        navigate({
          to: {
            type: "uri",
            uri: cognitoPaths.signIn,
          },
          replace: true,
          state: {
            message: {
              level: AuthMessageLevel.ERROR,
              tx: "auth.migrate.linkInvalidError",
            },
          },
        });
        return;
      }

      const clientMetadata: CognitoClientMetadata & {
        [key: string]: string;
      } = {
        disease,
        ...(organisation ? { organisation } : {}),
        isLocalhost: process.env.NODE_ENV === "development" ? "1" : "0",
        language,
      };
      signIn({
        username: email,
        password: tmpPassword,
        options: { clientMetadata },
      })
        .then(({ isSignedIn, nextStep }) => {
          if (
            isSignedIn &&
            nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
          ) {
            setSignedIn(isSignedIn);
          } else {
            signOut().then(() => {
              navigate({
                to: {
                  type: "uri",
                  uri: cognitoPaths.signIn,
                },
                replace: true,
                state: {
                  message: {
                    level: AuthMessageLevel.ERROR,
                    tx: "auth.migrate.signInSucceededUnexpectedlyError",
                  },
                },
              });
            });
          }
        })
        .catch(() => {
          navigate({
            to: {
              type: "uri",
              uri: cognitoPaths.signIn,
            },
            replace: true,
            state: {
              message: {
                level: AuthMessageLevel.ERROR,
                tx: "auth.migrate.linkInvalidError",
              },
            },
          });
        });
    }
  }, [params, navigate, email, cognitoState, disease, organisation, language]);

  return signedIn && email ? (
    <CognitoMigrateAccountWithConfirmedUser email={email} />
  ) : (
    <div className="min-h-16 flex flex-row items-center justify-center self-stretch">
      <LoadingSpinner
        message={{ tx: "auth.migrate.loadingMigrationPageStatusMessage" }}
      />
    </div>
  );
};
